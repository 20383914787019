export default function HomeItemList({ img, name }) {

    return (
        // <a href={`/stores/${name.toLowerCase()}`} style={{ textDecoration: 'none' }}>
            <div className="item-div">
                <img id="item-img" src={img} alt="Product" />
                <p style={{ color: 'black', fontWeight: 100 }} className="item-name">
                    {name}
                </p>
            </div>
        // </a>
    );
}