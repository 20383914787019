import '../css/SignIn.css'
import * as React from 'react';
import { useNavigate  } from 'react-router-dom';
import { useState } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularIndeterminate from '../components/CircularLoad';
import SimpleAlert from '../components/SimpleAlert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SessionAlert from '../components/SessionAlert';
import { useForm } from "react-hook-form";

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="/">
        Delivery
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignIn({message, type, alertDisplay}) {
  const [loader, setLoader] = useState(false);
  const [emailFound, setEmailFound] = useState('none');
  const [emailNotFound, setEmailNotFound] = useState('none');
  const [loginFailedDisplay, setLoginFailedDisplay] = useState('none');
  const [open, setOpen] = React.useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const loginOptions = {
    username: { required: "Username cannot be blank" },
    password: {
      required: "Password is required",
      minLength: {
        value: 8,
        message: "Password must be at least 8 characters",
      },
    }
  };

  const handleClickOpen = () => {
    setEmailFound('none')
    setEmailNotFound('none')
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();

  const handleLogin = async (formData) => {
      setLoader(true);
      setLoginFailedDisplay('none')
      try {
          const response = await fetch("/api/user/login", {
              method: "POST",
              headers: {
                  "Content-Type": "application/json"
              },
              body: JSON.stringify(formData),
              credentials: 'include', // Send cookies with the request
          });

          if (response.status === 200) {
            sessionStorage.setItem('sessionMessage', 'Welcome back!');
            navigate("/");
            window.location.reload();
          } else if (response.status === 401) {
              console.error("Incorrect credentials");
              setLoginFailedDisplay('block')
          } else {
              console.error("Login failed");
              // Handle other login errors
          }
          setLoader(false);
      } catch (error) {
          console.error("Error:", error);
          setLoader(false);
      }
  };
  const handleError = (errors) => {};

  const handleForgotPass = (event) => {
        event.preventDefault();
        setEmailFound('none')
        setEmailNotFound('none')
        const formData = new FormData(event.currentTarget);
        const formJson = Object.fromEntries(formData.entries());
        const email = formJson.email;
        fetch('/api/user/changePassEmail', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
               email
            })
        })
        .then(response => {
            if (response.status === 200) {
                setEmailFound('block')
            } else if (response.status === 401) {
                setEmailNotFound('block')
                response.text().then(errorMessage => {
                    console.error(`Server error: ${errorMessage}`);
                });
            } else {
                console.error(`Server error: ${response.status}`);
            }
        })
        .catch(error => {
            console.error('Fetch error:', error);
        });
  }

  return (
    <div key="alert-container">
        <SessionAlert/>
        <SimpleAlert key="message-alert" message={message} type={type} display={alertDisplay}/>
        <SimpleAlert key="login-failed-alert" message='Username or password incorrect' type='error' display={loginFailedDisplay}/>
        <ThemeProvider key="theme-provider" theme={defaultTheme}>
            <Container key="main-container" className='signin-container' component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    key="box"
                    sx={{
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"
                    }}
                >
                    <Avatar key="avatar" sx={{ m: 1, bgcolor: "black" }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography key="sign-in-title" component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <Box key="form-box" component="form" onSubmit={handleSubmit(handleLogin, handleError)} noValidate sx={{ mt: 1 }}>
                        <TextField
                            key="username-input"
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Username"
                            name="username"
                            autoComplete="username"
                            autoFocus
                            {...register("username", loginOptions.username)}
                        />
                        <small className="text-danger">
                            {errors?.username && errors.username.message}
                        </small>
                        <TextField
                            key="password-input"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            {...register("password", loginOptions.password)}
                        />
                        <small className="text-danger">
                            {errors?.password && errors.password.message}
                        </small>
                        <FormControlLabel
                            key="remember-me-checkbox"
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        />
                        <Button
                            key="sign-in-button"
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2, bgcolor: "black" }}
                        >
                            Sign In
                        </Button>
                        <Grid container key="grid-container">
                            <Grid item xs key="forgot-password-link">
                                <Link id="forgotpass-link" onClick={handleClickOpen} variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid>
                            <Grid item key="sign-up-link">
                                <Link href="/user/signup" variant="body2">
                                    {"Don't have an account? Sign Up"}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                    <div style={{marginTop: '30px'}}>
                        <CircularIndeterminate key="loading-spinner" display={loader}/>
                    </div>
                </Box>
            </Container>
        </ThemeProvider>

        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: handleForgotPass
                }}
            >
                <DialogTitle>Reset password</DialogTitle>
                <SimpleAlert key="message-alert" message="Email sent" type="success" display={emailFound} close={false}/>
                <SimpleAlert key="message-alert" message="Email not registered. You may register here" type="error" display={emailNotFound} close={false}/>
                <DialogContent>
                <DialogContentText>
                    To change the password, enter your email and an email will be sent to your inbox in order to reset your password.
                </DialogContentText>
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="name"
                    name="email"
                    label="Email Address"
                    type="email"
                    fullWidth
                    variant="standard"
                />
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit">Submit</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    </div>
  );
}