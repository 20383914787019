import '../css/FavoriteStores.css'
import { useEffect, useState } from "react";
import CircularIndeterminate from '../components/CircularLoad';
import StoreCard from '../components/StoreCard';

export default function FavoriteStores(){
    const [stores, setStores] = useState(null);
    const [alertDisplay, setAlertDisplay] = useState('none');

    useEffect(() => {
        fetchFavoriteStores();
    }, []);
    
    const fetchFavoriteStores = async () => {
        try {
          const response = await fetch('/api/user/favorite-stores');
          if (response.ok) {
            const data = await response.json();
            setStores(data.favorite_stores);
          } else {
            console.error('Failed to fetch favorite stores');
          }
        } catch (error) {
          console.error('Error:', error);
        }
    };

    async function removeFromFavorites(storeID) {
        try {
            const storeElement = document.getElementById(`favoritestores-card-${storeID}`);
            if (storeElement) {
                storeElement.remove();
            }
            const response = await fetch('/api/user/favorite-stores', {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ storeID })
            });
            if (response.ok) {
            } else {
                console.error("Error");
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    if (stores === null) {
        return (
            <div id="secondary-loading">
                <CircularIndeterminate display={true}/>
            </div>
        )
    }

    if (stores.length === 0) {
        return (
            <div style={{position: 'absolute', top: '40%', left: '50%', transform: 'translateX(-50%)'}}>
                <h1>No favorite stores currently</h1>
            </div>
        )
    }

    return (
        <section id='favoritestores-container'>
            {stores.map(store => (
                <StoreCard key={store._id} store={store}/>
            ))}
        </section>
    );
}